<template>
  <RouterView />
</template>

<script lang="ts">
import { useAuthStore } from './stores/auth'

export default {
  setup() {
    const authStore = useAuthStore()
    authStore.loadUserFromStorage() // Load user info from localStorage on app load

    return {}
  },
}
</script>

<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
