<template>
  <VaButtonToggle v-model="theme" color="background-element" border-color="background-element" :options="options" />
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

const { applyPreset, currentPresetName } = useColors()
const { t } = useI18n()

const options = [
  { label: t(''), value: 'dark', icon: 'contrast' },
  { label: t(''), value: 'light', icon: 'sunny' },
]

const theme = computed({
  get() {
    return localStorage.getItem('theme') || currentPresetName.value
  },
  set(value) {
    applyPreset(value)
    localStorage.setItem('theme', value)
  },
})

onMounted(() => {
  // Retrieve the theme from local storage on component mount
  theme.value = localStorage.getItem('theme') || currentPresetName.value
})
</script>
