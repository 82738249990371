import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'
import { useAuthStore } from '../stores/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'projects',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'slideshow',
        path: 'slideshow',
        component: () => import('../pages/slideshow/SlideshowPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'slideshow@add',
        path: 'slideshow/add',
        component: () => import('../pages/slideshow/SlideShowAddPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'slideshow@update',
        path: 'slideshow/update/:data',
        component: () => import('../pages/slideshow/SlideShowUpdatePage.vue'),
      },

      {
        name: 'article',
        path: 'article',
        component: () => import('../pages/article/ArticlesPage.vue'),
      },
      {
        name: 'articles@add',
        path: 'articles@add',
        component: () => import('../pages/article/ArticlesAddPage.vue'),
      },
      {
        name: 'articles@update',
        path: 'articles@update/:data',
        component: () => import('../pages/article/ArticleUpdatePage.vue'),
      },

      {
        name: 'documents',
        path: 'documents',
        component: () => import('../pages/documents/DocumentsPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'documents@add',
        path: 'documents@add',
        component: () => import('../pages/documents/DocumentsAddPage.vue'),
      },
      {
        name: 'documents@update',
        path: 'documents@update/:data',
        component: () => import('../pages/documents/DocumentsUpdatePage.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'logout',
        path: 'logout',
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          authStore.logout() // Call the logout action
          next({ name: 'login' }) // Redirect to the login page
        },
        component: { template: '<div>Logging out...</div>' },
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },

      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

function isAuthenticated(): boolean {
  // Replace this with your actual authentication check logic
  return !!localStorage.getItem('authToken')
}

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!isAuthenticated()) {
//       next({ name: 'login' })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  console.log('authStore.isAuthenticated', authStore.isAuthenticated)
  // If the user is authenticated and tries to access the login page, redirect them to the dashboard
  if (to.name === 'login' && authStore.isAuthenticated) {
    next({ name: 'dashboard' })
  } else if (to.matched.some((record) => record.meta.requiresAuth) && !authStore.isAuthenticated) {
    // If the route requires authentication and the user is not authenticated, redirect to login
    next({ name: 'login' })
  } else {
    next() // Otherwise, proceed as normal
  }
})

export default router
