// src/stores/auth.ts
import { defineStore } from 'pinia'
import ApiService from '../services/ApiService'

export interface User {
  id: number
  username: string
  // Add other user properties here
}

export interface AuthState {
  user: User | null
  token: string | null
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    token: null,
  }),

  actions: {
    async login(username: string, password: string) {
      try {
        const response = await ApiService.login(username, password)

        // Save the user info and token in the store
        this.user = { id: 1, username: username }
        this.token = response.data.access_token

        // Optionally save to localStorage for persistence
        localStorage.setItem('user', JSON.stringify(this.user))
        localStorage.setItem('token', this.token ?? '')

        // Optionally redirect after login
        // router.push({ name: 'Dashboard' });

        return response
      } catch (error) {
        throw new Error('Login failed. Please check your credentials.')
      }
    },

    logout() {
      // Clear user info and token
      this.user = null
      this.token = null

      // Remove from localStorage
      localStorage.removeItem('user')
      localStorage.removeItem('username')
      localStorage.removeItem('token')

      // Optionally redirect after logout
      // router.push({ name: 'Login' });
    },

    loadUserFromStorage() {
      const user = localStorage.getItem('user')
      const token = localStorage.getItem('token')
      console.log('token', token)
      if (token) {
        this.user = JSON.parse(user ?? '')
        this.token = token
      }
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    getUser: (state) => state.user,
    getAccessToken: (state) => state.token,
  },
})
