// src/services/ApiService.ts
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAuthStore } from '../stores/auth'

class ApiService {
  private axiosInstance: AxiosInstance

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      withCredentials: true, // Sends the cookie with requests
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    // Add an interceptor to include the token in every request, unless specified otherwise
    this.axiosInstance.interceptors.request.use((config) => {
      const authStore = useAuthStore()
      const token = authStore.getAccessToken
      // Skip adding the token if config.skipAuth is true
      if (token && config?.headers && !config.headers['skipAuth']) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    })
  }

  public async post(endpoint: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    try {
      return await this.axiosInstance.post(endpoint, data, config)
    } catch (error: any) {
      console.error(`POST request failed: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async login(username: string, password: string): Promise<AxiosResponse<any>> {
    try {
      const response = await this.post('/login', { username, password }, { headers: { skipAuth: true } })

      // Store the token in localStorage
      localStorage.setItem('username', username)

      console.log('Login successful')
      return response
    } catch (error: any) {
      console.error('Login failed:', error.message)
      throw new Error('Login failed. Please check your credentials.')
    }
  }
  //change password
  //SlideShow
  public async changePassword(userdata: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/change-password', userdata)
      console.log('Slideshow created successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to create slideshow: ${error.message}`)
      return Promise.reject(error)
    }
  }
  //SlideShow
  public async createSlideshow(slideshowData: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/slideshow', slideshowData)
      console.log('Slideshow created successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to create slideshow: ${error.message}`)
      return Promise.reject(error)
    }
  }

  //   curl --location 'http://127.0.0.1:8000/api/slideshow' \
  // --header 'Content-Type: application/json' \
  // --header 'Accept: application/json' \
  // --header 'Authorization: Bearer 57|LKgGbEH6R3ENGjNmHWj851RwIGKUPNjJbVyMMAqO90c355bc' \
  // --data '{
  //     "api_key": "SLSHOW168UPDATE",
  //     "id": 62,
  //     "name": "Updated Slideshow 55",
  //     "file_url": "http://example.com/updated_file.jpg",
  //     "date_upload": "2023-06-24",
  //     "thumbnail": "http://example.com/updated_thumbnail.jpg",
  //     "created_by": 2
  // }
  public async updateSlideshow(slideshowData: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/slideshow', slideshowData)
      console.log('Slideshow updated successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to update slideshow: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async deleteSlideshow(slideshowData: any): Promise<AxiosResponse<any>> {
    console.log('slideshowData', slideshowData)
    try {
      const response = await this.axiosInstance.post('/slideshow', slideshowData)
      console.log('Slideshow deleted successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to delete slideshow: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async getSlideshow(slideshowData: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/slideshow', slideshowData)
      console.log('Slideshow data retrieved successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to retrieve slideshow data: ${error.message}`)
      return Promise.reject(error)
    }
  }

  //documents

  public async getDocuments(page: number): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/docs', { action: 'API001910111', per_page: 10, page: page })
      console.log('Documents retrieved successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to retrieve documents: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async createDocument(docData: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/docs', docData)
      console.log('Document created successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to create document: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async updateDocument(docData: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/docs', docData)
      console.log('Document created successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to create document: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async deleteDocument(docId: number): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/docs', { action: 'delete', id: docId })
      console.log(`Document with ID ${docId} deleted successfully`)
      return response
    } catch (error: any) {
      console.error(`Failed to delete document: ${error.message}`)
      return Promise.reject(error)
    }
  }

  //article
  public async getArticles(page: string): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/articles-api', {
        api_key: 'ART000007',
        page: page,
        limit: '10',
      })
      console.log('Articles retrieved successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to retrieve articles: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async createArticle(articleData: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/articles-api', articleData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log('Article created successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to create article: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async updateArticle(data: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/articles-api', data)
      console.log('Article update successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to update article : ${error.message}`)
      return Promise.reject(error)
    }
  }

  //   curl --location 'http://127.0.0.1:8000/api/articles-api' \
  // --header 'Content-Type: application/json' \
  // --header 'Accept: application/json' \
  // --header 'Authorization: Bearer 57|LKgGbEH6R3ENGjNmHWj851RwIGKUPNjJbVyMMAqO90c355bc' \
  // --data '{"api_key": "ART000003", "id": 60}'
  public async getArticleById(articleID: number): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/articles-api', { api_key: 'ART000003', id: articleID })
      console.log(`Article with ID ${articleID} retrieved successfully`)
      return response
    } catch (error: any) {
      console.error(`Failed to retrieve article with ID ${articleID}: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async deleteArticle(articleID: number): Promise<AxiosResponse<any>> {
    console.log(`Article with ID ${articleID} deleteingggggg`)
    try {
      const response = await this.axiosInstance.post('/articles-api', { api_key: 'ART000005', id: articleID })
      console.log(`Article with ID ${articleID} deleted successfully`)
      return response
    } catch (error: any) {
      console.error(`Failed to delete document: ${error.message}`)
      return Promise.reject(error)
    }
  }

  public async getStatisticDashboard(): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axiosInstance.post('/admin-state', { date: '2024-09-11' })
      console.log('admin-state retrieved successfully')
      return response
    } catch (error: any) {
      console.error(`Failed to retrieve admin-state: ${error.message}`)
      return Promise.reject(error)
    }
  }
}

// export default new ApiService(process.env.VUE_APP_API_BASE_URL || 'http://127.0.0.1:8000/api');
export default new ApiService('https://api.preahyukunthor.edu.kh/api')
